<template>
  <div class="wrapper">
    <div class="page">
      <div id="main-loading-cover" :class="{'disabled_content':loading}">
        <div class="page-inner" id="main_page">
          <div class="va-card">
            <div class="row">
              <div class="col-md-12">
                <div class="dash_card_body">
                  <div class="header_section">
                    <h4 class="menu_header"> Reports</h4>
                  </div>
                  <div class="search_section">
                    <div class="row">
                      <div class="col-md-6 col-sm-12">
                        <div class="form-group mt-1">
                          <select v-model="report" @change="filterReport()" class="form-control">
                            <option value="">--Select Report --</option>
                            <option value="payments">Payment Report</option>
                            <option value="giftcards">Gift Cards Report</option>
                            <option value="redemptionactivities">Redemption Activities Report</option>
                            <option value="voidvouchers">Voided Gift Cards Report</option>
                            <option value="expiredvouchers">Expired Gift Cards Report</option>
                            <option value="duevouchers">Upcoming Expiry Gift Cards Report</option>
                            <option value="unusedvouchers">Unused Gift Cards Report</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <button class="btn btn_reserveport_orange mt-1 mr-1" @click="filterReport()"><span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span> search</button>
                        <button class="btn btn_reserveport_orange mt-1" @click="getExportData()" ><i v-if="exportLoarder" class="fa fa-spinner fa-spin"></i> &nbsp; <i v-if="!exportLoading"  class="fas fa-file-export"></i>   Export</button>

                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-2">
                        <Datepicker placeholder="Start Date" v-model="startDate"  utc  autoApply  :format="customFormatter"></Datepicker>
                      </div>
                      <div class="col-md-2">
                        <Datepicker placeholder="End Date" v-model="endDate"  utc  autoApply  :format="customFormatter"></Datepicker>
                      </div>
                      <div class="col-md-2" v-if="report !='duevouchers'">
                        <select class="form-control" id=""   v-model="location">
                          <option value="">Select Store</option>
                          <option v-for="store in stores" :key="store.id" :value="store.id">{{store.branch_name}}</option>
                        </select>
                      </div>

                      <div class="col-md-2" v-if="report =='duevouchers'">
                        <select class="form-control" id=""   v-model="period">
                          <option value="">Select Period From</option>
                          <option value="30">30 days</option>
                          <option value="20">20 days</option>
                          <option value="10">10 days</option>
                          <option value="5">5 days</option>
                        </select>
                      </div>


                    </div>
                  </div>
                
                </div>
                <hr>
              </div>
              <div class="col-md-12">
                <div class="dash_card_body">
                  <div class="summary_body">
                    <div v-if="type == 'redemptionbystore'">
                      <div class="row">
                        <div class="col-md-6">

                        </div>
                        <div class="col-md-6">

                        </div>
                      </div>
                    </div>
                    <!-- vouchers -->
                    <div v-if="report == 'giftcards'">
                      <div class="row">
                        <div class="col-md-12 col-sm-12">
                          <div class="cardbox card">
                            <h4>Active Gift Cards Report </h4>
                            <div class="card-body  ">
                              <div class="table_section">
                                <table class="table table-hover">
                                  <thead>
                                    <th>Date</th>
                                    <th>Voucher Name</th>
                                    <th>Code</th>
                                    <th>Voucher Amount</th> 
                                    <th>Type</th>
                                    <th>Buyer Details</th>	
                                    <th>Recipient Details</th>
                                    <th>Reference</th> 	 	 	 	 	 	 	 
                                  </thead>
                                  <tbody v-if="!loading">
                                    <tr v-for="voucher in vouchers" :key="voucher.id">
                                      <td>{{formatDate(voucher.created_at)}}</td>
                                      <td>{{voucher.title}}</td>
                                      <td>{{voucher.code}}</td>
                                      <td>
                                        <b>Amount:</b>{{formatPrice(voucher.amount)}} <br>
                                        <b>Balance:</b> {{formatPrice(voucher.balance)}}
                                      </td>
                                      <td>{{voucher.bulk_generated ? 'Bulk Generated' : 'Regular'}}</td>
                                      <td>
                                        <strong>Name:</strong> {{voucher.customer_first_name +' ' +voucher.customer_last_name}} <br>
                                        <strong>Email:</strong> {{voucher.customer_email}}<br>
                                        <strong>Phone:</strong> {{voucher.customer_phone}}
                                      </td>
                                      <td>
                                        <strong>Name:</strong> {{voucher.recipient_first_name +' ' +voucher.recipient_last_name}} <br>
                                        <strong>Email:</strong> {{voucher.recipient_email}}<br>
                                        <strong>Phone:</strong> {{voucher.recipient_phone}}
                                      </td>
                                      <td>{{voucher.reference}}</td>
                                    </tr>
                                  </tbody>
                                  <tfoot v-if="!loading">
                                    <tr>
                                      <td colspan="8">
                                        <div id="pagination" v-if="totalPages > 1">
                                          <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
                                          <ul class="showItems">
                                            <li>Show Items:
                                              <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                                <option :value="10">10</option>
                                                <option :value="20">20</option>
                                                <option :value="30">30</option>
                                                <option :value="40">40</option>
                                                <option :value="50">50</option>
                                              </select>
                                            </li>

                                          </ul>
                                        </div>

                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- redemptions -->
                    <div v-else-if="report =='redemptionactivities'">
                      <div class="row">
                        <div class="col-md-12 col-sm-12">
                          <div class="cardbox card">
                            <h4>Redemption Activities Report </h4>
                            <div class="card-body  ">
                              <div class="table_section">
                                <table class="table  table-hover">
                                  <thead>
                                    <th>Date</th>
                                    <th>Voucher Name</th>
                                    <th>Code</th>
                                    <th>Amount</th>
                                    <th>Source</th>
                                    <th>Location</th>
                                    <th>Admin Generated</th>
                                    <th>Reference</th>
                                  </thead>
                                  <tbody v-if="!loading">
                                    <tr v-for="redemption in redemptions" :key="redemption.id">
                                      <td>{{formatDate(redemption.created_at,1)}}</td>
                                      <td>{{redemption.title}}</td>
                                      <td>{{redemption.code}}</td>
                                      <td>{{formatPrice(redemption.amount)}}</td>
                                      <td>{{redemption.source}}</td>
                                      <td>{{redemption.location}}</td>
                                      <td>{{redemption.bulk_generated ? 'Yes' : 'No'}}</td>
                                      <td>{{redemption.reference}}</td>
                                    </tr>
                                  </tbody>
                                  <tfoot v-if="!loading">
                                    <tr>
                                      <td colspan="8">
                                        <div id="pagination" v-if="totalPages > 1">
                                          <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
                                          <ul class="showItems">
                                            <li>Show Items:
                                              <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                                <option :value="10">10</option>
                                                <option :value="20">20</option>
                                                <option :value="30">30</option>
                                                <option :value="40">40</option>
                                                <option :value="50">50</option>
                                              </select>
                                            </li>

                                          </ul>
                                        </div>

                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> 
                    <!-- for payments -->
                    <div v-else-if="report == 'payments'">
                      <div class="row">
                        <div class="col-md-12 col-sm-12">
                          <div class="cardbox card">
                            <h4>Payments Report </h4>
                            <div class="card-body  ">
                              <div class="table_section">
                                <table class="table  table-hover">
                                  <thead>
                                    <th scope="col">Giftcard Reference</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Phone</th>
                                    <th scope="col">Amount <b>({{pesapalConfig.currency_code}} )</b> </th>
                                    <th scope="col">Payment Method</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Date</th>
                                  </thead>
                                  <tbody v-if="!loading">
                                    <tr v-for="payment in payments" :key="payment.id">
                                      <td>{{payment.voucher_reference}}</td>
                                      <td>{{payment.payer}}</td>
                                      <td>{{payment.payer_email}}</td>
                                      <td>{{payment.phone}}</td>
                                      <td>{{payment.amount}}</td>
                                      <td>{{payment.method ? payment.method : 'N/A'}}</td>
                                      <td>
                                        <span :style="payment.status == 'COMPLETED'?'color:#149F35;': 'color:#EC850C;'"> <b>{{payment.status}}</b></span> <br> <br>
                                      </td>
                                      <td>
                                        {{formatDate(payment.created_at)}}
                                      </td>
                          
                                    </tr>
                                  </tbody>
                                  <tfoot v-if="!loading">
                                    <tr>
                                      <td colspan="8">
                                        <div id="pagination" v-if="totalPages > 1">
                                          <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
                                          <ul class="showItems">
                                            <li>Show Items:
                                              <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                                <option :value="10">10</option>
                                                <option :value="20">20</option>
                                                <option :value="30">30</option>
                                                <option :value="40">40</option>
                                                <option :value="50">50</option>
                                              </select>
                                            </li>

                                          </ul>
                                        </div>

                                      </td>
                                    </tr>
                                  </tfoot>

                                </table>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>   
                    </div>
                    <div v-else-if="report =='voidvouchers'">
                      <div class="row">
                        <div class="col-md-12 col-sm-12">
                          <div class="cardbox card">
                            <h4>Voided Gift Cards Report </h4>
                            <div class="card-body  ">
                              <div class="table_section">
                                <table class="table table-hover">
                                  <thead>
                                    <th>Date</th>
                                    <th>Voucher Name</th>
                                    <th>Code</th>
                                    <th>Voucher Amount</th> 
                                    <th>Type</th>
                                    <th>Buyer Details</th>	
                                    <th>Recipient Details</th>
                                    <th>Reference</th> 	
                                    <th>Void Reason</th> 	 	 	 	 	 	 
                                  </thead>
                                  <tbody v-if="!loading">
                                    <tr v-for="voucher in voidedVouchers" :key="voucher.id">
                                      <td>{{formatDate(voucher.created_at)}}</td>
                                      <td>{{voucher.title}}</td>
                                      <td>{{voucher.code}}</td>
                                      <td>
                                        <b>Amount:</b>{{formatPrice(voucher.amount)}} <br>
                                        <b>Balance:</b> {{formatPrice(voucher.balance)}}
                                      </td>
                                      <td>{{voucher.bulk_generated ? 'Bulk Generated' : 'Regular'}}</td>
                                      <td>
                                        <strong>Name:</strong> {{voucher.customer_first_name +' ' +voucher.customer_last_name}} <br>
                                        <strong>Email:</strong> {{voucher.customer_email}}<br>
                                        <strong>Phone:</strong> {{voucher.customer_phone}}
                                      </td>
                                      <td>
                                        <strong>Name:</strong> {{voucher.recipient_first_name +' ' +voucher.recipient_last_name}} <br>
                                        <strong>Email:</strong> {{voucher.recipient_email}}<br>
                                        <strong>Phone:</strong> {{voucher.recipient_phone}}
                                      </td>
                                      <td>{{voucher.reference}}</td>
                                      <td>
                                        <span v-if="voucher.void_reason !='other'">
                                          {{ voucher.void_reason }}
                                        </span>
                                        <span v-else>{{ voucher.void_remarks }}</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tfoot v-if="!loading">
                                    <tr>
                                      <td colspan="8">
                                        <div id="pagination" v-if="totalPages > 1">
                                          <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
                                          <ul class="showItems">
                                            <li>Show Items:
                                              <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                                <option :value="10">10</option>
                                                <option :value="20">20</option>
                                                <option :value="30">30</option>
                                                <option :value="40">40</option>
                                                <option :value="50">50</option>
                                              </select>
                                            </li>

                                          </ul>
                                        </div>

                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="report =='expiredvouchers'">
                      <div class="row">
                        <div class="col-md-12 col-sm-12">
                          <div class="cardbox card">
                            <h4>Expired Gift Cards Report </h4>
                            <div class="card-body  ">
                              <div class="table_section">
                                <table class="table table-hover">
                                  <thead>
                                    <th>Date</th>
                                    <th>Voucher Name</th>
                                    <th>Code</th>
                                    <th>Voucher Amount</th> 
                                    <th>Type</th>
                                    <th>Buyer Details</th>	
                                    <th>Recipient Details</th>
                                    <th>Reference</th> 	
                                    <th>Expiry Date</th> 	
                                  </thead>
                                  <tbody v-if="!loading && expiredVouchers.length > 0">
                                    <tr v-for="voucher in expiredVouchers" :key="voucher.id">
                                      <td>{{formatDate(voucher.created_at)}}</td>
                                      <td>{{voucher.title}}</td>
                                      <td>{{voucher.code}}</td>
                                      <td>
                                        <b>Amount:</b>{{formatPrice(voucher.amount)}} <br>
                                        <b>Balance:</b> {{formatPrice(voucher.balance)}}
                                      </td>
                                      <td>{{voucher.bulk_generated ? 'Bulk Generated' : 'Regular'}}</td>
                                      <td>
                                        <strong>Name:</strong> {{voucher.customer_first_name +' ' +voucher.customer_last_name}} <br>
                                        <strong>Email:</strong> {{voucher.customer_email}}<br>
                                        <strong>Phone:</strong> {{voucher.customer_phone}}
                                      </td>
                                      <td>
                                        <strong>Name:</strong> {{voucher.recipient_first_name +' ' +voucher.recipient_last_name}} <br>
                                        <strong>Email:</strong> {{voucher.recipient_email}}<br>
                                        <strong>Phone:</strong> {{voucher.recipient_phone}}
                                      </td>
                                      <td>{{voucher.reference}}</td>
                                      <td>
                                        {{ formatDate(voucher.expiry_date) }}
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tfoot v-if="!loading">
                                    <tr>
                                      <td colspan="9">
                                        <div id="pagination" v-if="totalPages > 1">
                                          <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
                                          <ul class="showItems">
                                            <li>Show Items:
                                              <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                                <option :value="10">10</option>
                                                <option :value="20">20</option>
                                                <option :value="30">30</option>
                                                <option :value="40">40</option>
                                                <option :value="50">50</option>
                                              </select>
                                            </li>

                                          </ul>
                                        </div>

                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-else-if="report =='duevouchers'">
                      <div class="row">
                        <div class="col-md-12 col-sm-12">
                          <div class="cardbox card">
                            <h4>Upcoming Expiry Gift Cards Report</h4>
                            <div class="card-body  ">
                              <div class="table_section">
                                <table class="table table-hover">
                                  <thead>
                                    
                                    <th>Code</th>
                                    <th>Reference</th> 
                                    <th>Voucher Amount</th> 
                                    <th>Type</th>
                                    <th>Buyer Details</th>	
                                    <th>Recipient Details</th>
                                    <th>Date Bought</th>	
                                    <th>Expiry Date</th> 
                                    <th>Days Remining( <small>from current date</small>)</th>	
                                  </thead>
                                  <tbody v-if="!loading && duevouchers.length > 0">
                                    <tr v-for="voucher in duevouchers" :key="voucher.id">
                                     
                                      <td>{{voucher.code}}</td>
                                      <td>{{voucher.reference}}</td>
                                      <td>
                                        <b>Amount:</b>{{formatPrice(voucher.amount)}} <br>
                                        <b>Balance:</b> {{formatPrice(voucher.balance)}}
                                      </td>
                                      <td>{{voucher.bulk_generated ? 'Bulk Generated' : 'Regular'}}</td>
                                      <td>
                                        <strong>Name:</strong> {{voucher.customer_first_name +' ' +voucher.customer_last_name}} <br>
                                        <strong>Email:</strong> {{voucher.customer_email}}<br>
                                        <strong>Phone:</strong> {{voucher.customer_phone}}
                                      </td>
                                      <td>
                                        <strong>Name:</strong> {{voucher.recipient_first_name +' ' +voucher.recipient_last_name}} <br>
                                        <strong>Email:</strong> {{voucher.recipient_email}}<br>
                                        <strong>Phone:</strong> {{voucher.recipient_phone}}
                                      </td>
                                     
                                      <td>{{formatDate(voucher.created_at)}}</td>
                                      <td>
                                        {{ formatDate(voucher.expiry_date) }}
                                      </td>
                                      <td>
                                        {{ voucher.days_to_expire }} day(s)
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tfoot v-if="!loading && report !='duevouchers'">
                                    <tr>
                                      <td colspan="9">
                                        <div id="pagination" v-if="totalPages > 1">
                                          <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
                                          <ul class="showItems">
                                            <li>Show Items:
                                              <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                                <option :value="10">10</option>
                                                <option :value="20">20</option>
                                                <option :value="30">30</option>
                                                <option :value="40">40</option>
                                                <option :value="50">50</option>
                                              </select>
                                            </li>

                                          </ul>
                                        </div>

                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="report =='unusedvouchers'">
                      <div class="row">
                        <div class="col-md-12 col-sm-12">
                          <div class="cardbox card">
                            <h4>Unused Gift Cards Report </h4>
                            <div class="card-body  ">
                              <div class="table_section">
                                <table class="table table-hover">
                                  <thead>
                                    
                                    <th>Code</th>
                                    <th>Reference</th> 
                                    <th>Voucher Amount</th> 
                                    <th>Buyer Details</th>	
                                    <th>Recipient Details</th>
                                    <th>Date Bought</th>	
                                    <th>Expiry Date</th> 
                                    <th>Voucher Type</th>	
                                  </thead>
                                  <tbody v-if="!loading && unusedvouchers.length > 0">
                                    <tr v-for="voucher in unusedvouchers" :key="voucher.id">
                                     
                                      <td>{{voucher.code}}</td>
                                      <td>{{voucher.reference}}</td>
                                      <td>
                                        <b>Amount:</b>{{formatPrice(voucher.amount)}} <br>
                                        <b>Balance:</b> {{formatPrice(voucher.balance)}}
                                      </td>
                                      <td>
                                        <strong>Name:</strong> {{voucher.customer_first_name +' ' +voucher.customer_last_name}} <br>
                                        <strong>Email:</strong> {{voucher.customer_email}}<br>
                                        <strong>Phone:</strong> {{voucher.customer_phone}}
                                      </td>
                                      <td>
                                        <strong>Name:</strong> {{voucher.recipient_first_name +' ' +voucher.recipient_last_name}} <br>
                                        <strong>Email:</strong> {{voucher.recipient_email}}<br>
                                        <strong>Phone:</strong> {{voucher.recipient_phone}}
                                      </td>
                                     
                                      <td>{{formatDate(voucher.created_at)}}</td>
                                      <td>
                                        {{ formatDate(voucher.expiry_date) }}
                                      </td>
                                      <td>
                                        {{ voucher.voucher_type }}
                                      </td>
                                    </tr>
                                  </tbody>
                                  <tfoot v-if="!loading && report !='duevouchers'">
                                    <tr>
                                      <td colspan="9">
                                        <div id="pagination" v-if="totalPages > 1">
                                          <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
                                          <ul class="showItems">
                                            <li>Show Items:
                                              <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                                <option :value="10">10</option>
                                                <option :value="20">20</option>
                                                <option :value="30">30</option>
                                                <option :value="40">40</option>
                                                <option :value="50">50</option>
                                              </select>
                                            </li>

                                          </ul>
                                        </div>

                                      </td>
                                    </tr>
                                  </tfoot>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <!--  -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="main-loader"></div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import ReusableDataMixins from '@/mixins/ReusableDataMixins';
import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
import Pagination from '@/pages/pagination/pagination.vue';
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
    components: {
        Pagination,
    },
    data() {
        return {
          redemptions: [],
          vouchers: [],
          payments: [],
          summary: [],
          voidedVouchers: [],
          expiredVouchers:[],
          loading: true,
          startDate:this.$store.state.gcardstartDate,
          endDate: this.$store.state.gcardendDate,
          location: this.$store.state.gcardlocation,
          stores: [],
          limit: 10,
          report:this.$route.params.type,
          page: 1,
          totalPages: 10,
          itemSearch: '',
          categorySearch: this.$store.state.itemCategorySearch,
          totalRecords: 0,
          recordsPerPage: 10,
          duevouchers:[],
          unusedvouchers:[],
          exportLoarder:false,
          period: ""
        }
    },
   
    mounted: async function (){
        this.getReportDetails()
        this.getBranches();
        this.startDate = this.$store.state.gcardstartDate ? this.$store.state.gcardstartDate : moment().startOf('month').format('YYYY-MM-DD');
        this.endDate =  this.$store.state.gcardendDate ?  this.$store.state.gcardendDate : moment().format('YYYY-MM-DD');
    },
    methods: {
      filterReport(){
        if(this.report !=''){
          this.$router.push({
              name: 'reportDetail',
              params:{
                type:this.report
              }
          });
        }else{
          this.$router.push({
              name: 'reports',
          });
        
        }
        this.getReportDetails();
      },
      customFormatter(date) {
        return moment(date).format('YYYY-MM-DD');
      },
      drawChart () {
        // 
      },
      async getExportData(){
        this.exportLoarder = true;
        try { 
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
         
          let url = `${this.dashBaseUrl}/report-summary-details/${this.merchantID}`;
          url = new URL(url);
          if(this.startDate !==''){
            url.searchParams.set('start_date', moment(this.startDate).format('YYYY-MM-DD'));
          }
          if(this.endDate !== ''){
             url.searchParams.set('end_date', moment(this.endDate).format('YYYY-MM-DD'));
          }
          if(this.location !==''){
            url.searchParams.set('store', this.location);
          }
          if(this.period !==''){
            url.searchParams('period',this.period);
          }
          const response = await this.axios.get(url,config);
          if(response.status == 200){
            this.exportLoarder = false
            console.log('response data', response.data.data)
            let fileName = ''
            let exportData = ''
            if(this.report == 'payments'){
              const payments = response.data.data.payments;
              const paymentDetails = [];
              for(const payment of Object.values(payments)){ 
                paymentDetails.push({
                  'Giftcard Reference': payment.voucher_reference,
                  'Name':payment.payer,
                  'Email':payment.payer_email,
                  'Phone':payment.phone,
                  'Amount': payment.currency ? payment.currency : 'KES'+ ' ' + payment.amount,
                  'Payment Method':payment.method ? payment.method : 'N/A',
                  'Status':payment.status,
                  'Date':this.formatDate(payment.created_at),
                })
              }
              fileName = Date.now() + '_payment_report'
              exportData = paymentDetails   
            } else if(this.report == 'giftcards'){
              const giftcards = response.data.data.gift_vouchers
              const giftcardDetails = []
              for(const giftcard of Object.values(giftcards)){ 
                giftcardDetails.push({
                  'Date':this.formatDate(giftcard.created_at),
                  'Name':giftcard.title,
                  'Code':giftcard.code,
                  'Amount':giftcard.amount,
                  'Balance':giftcard.balance,
                  'Type': giftcard.bulk_generated ? 'Bulk Generated' : 'Regular',
                  'Buyer Name':giftcard.customer_first_name +' '+giftcard.customer_last_name,
                  'Buyer Email':giftcard.customer_email,
                  'Buyer Phone':giftcard.customer_phone,
                  'Recipient Name':giftcard.recipient_first_name +' ' +giftcard.recipient_last_name,
                  'Recipient Email':giftcard.recipient_email,
                  'Recipient Phone':giftcard.recipient_phone,
                  'Reference':giftcard.reference
                })
              }
              fileName = Date.now() +'_active_gift_cards_report'
              exportData = giftcardDetails
            } else if(this.report == 'redemptionactivities'){
              const redemptionactivities = response.data.data.redemptions
              const redemptionDetails = []
              for(const redemption of Object.values(redemptionactivities)){
                redemptionDetails.push({
                  'Date':this.formatDate(redemption.created_at),
                  'Name':redemption.title,
                  'Code':redemption.code,
                  'Amount':redemption.amount,
                  'Source':redemption.source,
                  'Location':redemption.location,
                  'Admin Generated':redemption.bulk_generated ? 'Yes' : 'No',
                  'Reference':redemption.reference
                })
              }
              fileName = Date.now() + '_redemptionactivities_report'
              exportData = redemptionDetails
            }else if(this.report == 'voidvouchers'){
              const voidedVouchers = response.data.data.voided_vouchers
              const voidedVoucherDetails = []
              for(const voucher of Object.values(voidedVouchers)){
                voidedVoucherDetails.push({
                  'Date':this.formatDate(voucher.created_at),
                  'Name':voucher.title,
                  'Code':voucher.code,
                  'Amount':this.formatPrice(voucher.amount),
                  'Balance': this.formatPrice(voucher.balance),
                  'Type':voucher.bulk_generated ? 'Bulk Generated' : 'Regular',
                  'Buyer Name':voucher.customer_first_name +' '+voucher.customer_last_name,
                  'Buyer Email':voucher.customer_email,
                  'Buyer Phone':voucher.customer_phone,
                  'Recipient Name':voucher.recipient_first_name +' ' +voucher.recipient_last_name,
                  'Recipient Email':voucher.recipient_email,
                  'Recipient Phone':voucher.recipient_phone,
                  'Reference':voucher.reference,
                  'Void Reason': voucher.void_reason !='other' ? voucher.void_reason : voucher.void_remarks
                })
              }
              fileName = Date.now() + '_voidvouchers_report'
              exportData = voidedVoucherDetails
            }else if(this.report == 'expiredvouchers'){
              const evouchers = response.data.data.expired_vouchers
              const expiredvoucherDetails = []
              for(const voucher of Object.values(evouchers)){
                expiredvoucherDetails.push({
                  'Date':this.formatDate(voucher.created_at),
                  'Name':voucher.title,
                  'Code':voucher.code,
                  'Amount':this.formatPrice(voucher.amount),
                  'Balance': this.formatPrice(voucher.balance),
                  'Type':voucher.bulk_generated ? 'Bulk Generated' : 'Regular',
                  'Buyer Name':voucher.customer_first_name +' '+voucher.customer_last_name,
                  'Buyer Email':voucher.customer_email,
                  'Buyer Phone':voucher.customer_phone,
                  'Recipient Name':voucher.recipient_first_name +' ' +voucher.recipient_last_name,
                  'Recipient Email':voucher.recipient_email,
                  'Recipient Phone':voucher.recipient_phone,
                  'Reference':voucher.reference,
                  'Expiry Date': this.formatDate(voucher.expiry_date),
                  'Expiry Period':voucher.expiry_period
                })
              }
              fileName = Date.now() + '_expired_vouchers_report'
              exportData = expiredvoucherDetails
            }else if(this.report == 'duevouchers'){
              const duevouchers = response.data.data.due_vouchers.data
              const duevouchersItems = []
              for(const voucher of Object.values(duevouchers)){
                duevouchersItems.push({
                  'Code':voucher.code,
                  'Reference':voucher.reference,
                  'Amount':this.formatPrice(voucher.amount),
                  'Balance': this.formatPrice(voucher.balance),
                  'Type':voucher.bulk_generated ? 'Bulk Generated' : 'Regular',
                  'Buyer Name':voucher.customer_first_name +' '+voucher.customer_last_name,
                  'Buyer Email':voucher.customer_email,
                  'Buyer Phone':voucher.customer_phone,
                  'Recipient Name':voucher.recipient_first_name +' ' +voucher.recipient_last_name,
                  'Recipient Email':voucher.recipient_email,
                  'Recipient Phone':voucher.recipient_phone,
                  'Date Bought':this.formatDate(voucher.created_at),
                  'Expiry Date': this.formatDate(voucher.expiry_date),
                  'Expiry Period':voucher.expiry_period ? voucher.expiry_period : 'N/A'
                })
              }
              fileName = Date.now() + '_upcoming_expiry_report'
              exportData = duevouchersItems

            }else if(this.report == 'unusedvouchers'){
              const unusedvouchers = response.data.data.unused_vouchers
              const unusedvoucherDetails = []
              for(const voucher of Object.values(unusedvouchers)){
                unusedvoucherDetails.push({
                  'Code':voucher.code,
                  'Reference':voucher.reference,
                  'Amount':this.formatPrice(voucher.amount),
                  'Balance': this.formatPrice(voucher.balance),
                  'Type':voucher.bulk_generated ? 'Bulk Generated' : 'Regular',
                  'Buyer Name':voucher.customer_first_name +' '+voucher.customer_last_name,
                  'Buyer Email':voucher.customer_email,
                  'Buyer Phone':voucher.customer_phone,
                  'Recipient Name':voucher.recipient_first_name +' ' +voucher.recipient_last_name,
                  'Recipient Email':voucher.recipient_email,
                  'Recipient Phone':voucher.recipient_phone,
                  'Date Bought':this.formatDate(voucher.created_at),
                  'Expiry Date': this.formatDate(voucher.expiry_date),
                  'Voucher Type':voucher.voucher_type
                })
              }
              fileName = Date.now() + '_unused_vouchers_report'
              exportData = unusedvoucherDetails  
            }
            this.exportExcel(exportData, fileName)


          }
        } catch (error) {
          this.exportLoarder = false;
          toast.error("Error exporting report")
          console.log("exporting error",error)
          if(error.response && error.response.status == 401){
            this.$router.push({
                name: 'login'
            });
          }
          
        }
      },
      async getReportDetails(){
        this.loading = true;
        const loader = document.getElementById('main-loader');
        loader.style.display = 'block';
        const payload = {
          'start_date': this.startDate,
          'end_date': this.endDate,
          'location': this.location,
          'size': this.recordsPerPage
        }
        this.$store.commit('updateGvReportSearchData', payload)
        try{
          const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
          };
          
          let url = `${this.dashBaseUrl}/report-summary-details/${this.merchantID}`;
          url = new URL(url);
          if(this.startDate !==''){
            url.searchParams.set('start_date', moment(this.startDate).format('YYYY-MM-DD') );
          }
          if(this.endDate !== ''){
             url.searchParams.set('end_date', moment(this.endDate).format('YYYY-MM-DD'));
          }
          if(this.location !==''){
            url.searchParams.set('store', this.location);
          }
          if(this.period !==''){
            url.searchParams('period',this.period);
          }
          url.searchParams.set('size', this.recordsPerPage);
          url.searchParams.set('page', this.page);
          const response = await this.axios.get(url,config);
          if(response.status == 200){
            this.loading = false;
            this.summary = response.data.data
            this.redemptions = response.data.data.redemptions.data !=undefined? response.data.data.redemptions.data: response.data.data.redemptions;
            this.vouchers = response.data.data.gift_vouchers.data;
            this.payments = response.data.data.payments.data;
            this.expiredVouchers = response.data.data.expired_vouchers.data;
            this.voidedVouchers = response.data.data.voided_vouchers.data
            this.duevouchers = response.data.data.due_vouchers.data
            this.unusedvouchers = response.data.data.unused_vouchers.data
            if(this.report == 'giftcards'){
              this.totalPages = Math.ceil(response.data.data.gift_vouchers.total / this.recordsPerPage);
              this.totalRecords = response.data.data.gift_vouchers.total;
            }
            if(this.report == 'voidvouchers'){
              this.totalPages = Math.ceil(response.data.data.voided_vouchers.total / this.recordsPerPage);
              this.totalRecords = response.data.data.voided_vouchers.total;
            }
            if(this.report == 'payments'){
              this.totalPages = Math.ceil(response.data.data.payments.total / this.recordsPerPage);
              this.totalRecords = response.data.data.payments.total;
            }
            if(this.report == 'unusedvouchers'){
              this.totalPages = Math.ceil(response.data.data.unused_vouchers.total / this.recordsPerPage);
              this.totalRecords = response.data.data.unused_vouchers.total;
            }
            if(this.report == 'redemptionactivities'){
              if(response.data.data.redemptions.total != undefined){
                this.totalPages = Math.ceil(response.data.data.redemptions.total / this.recordsPerPage);
                this.totalRecords = response.data.data.redemptions.total;
              }else{
                this.totalPages =0;
              }
             
            }
            if (this.report =='duevouchers') {
              // 
              
            }
            if(this.report == 'voidvouchers'){
              this.totalPages = Math.ceil(response.data.data.voided_vouchers.total / this.recordsPerPage);
             this.totalRecords = response.data.data.voided_vouchers.total;
            }
            if(this.report == 'expiredvouchers'){
              this.totalPages = Math.ceil(response.data.data.expired_vouchers.total / this.recordsPerPage);
            this.totalRecords = response.data.data.expired_vouchers.total;
            }
            loader.style.display = 'none';
          }  
        }catch(error){
          this.loading  = false;
          loader.style.display = 'none';
          if(error.response.status === 401){
                    this.$router.push({
                        name: 'login'
                    });
          }
        }
      },
      async getBranches() {
            const url = this.baseUrl + "/branch?merchant_id="+ this.merchantID;
            this.axios.get(url, this.apiRequestConfig()).then((response) => {
                if (response.status === 200) {
                    if (response.data.data !== null) {
                        const stores = response.data.data;
                        this.stores = stores;
                    }
                }
            }).catch((err) => {
                console.log('error', err);
            })
      },
      onPageChange(page){
            this.page = page;
            this.getReportDetails();
        },
        onChangeRecordsPerPage(){
          this.getReportDetails();
        },
       
    },
     mixins: [ReusableDataMixins,ReusableFunctionMixins]
}
</script>
